:root {
  --primary-color: #009b94;
  --text-color: #1e1e1e;
  --primary-font: poppins;
  --secondary-font: robotto;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  background-color: #e5e5e5 !important;
  font-family: var(--primary-font);
  color: #474747;
}
a {
  color: #1e1e1e;
  text-decoration: none !important;
}
a:hover {
  text-decoration: none !important;
  color: unset;
}
.custom-button {
  outline: none;
  border: none;
  color: #666666;
  margin: 0 15px;
  padding: 5px;
  border: 1px solid transparent;
}
.custom-button:focus {
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  box-shadow: 0 0 0 0.2rem rgba(0 155 148 /25%) !important;
}
.custom-button:hover {
  color: var(--primary-color);
}
.react-tag-input:hover {
  border-color: #1e1e1e;
}
.react-tag-input:focus-within {
  border: 1px solid #009b94;
}
.css-1phdzq7-MuiButtonBase-root-MuiToggleButton-root.Mui-selected {
  color: #fff !important;
  background-color: #009b94 !important;
}
/* check box */
.checkbox-wrapper-17 input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
}

.checkbox-wrapper-17 label {
  --size: 40px;

  cursor: pointer;
  width: var(--size);
  height: calc(var(--size) / 2);
  background: grey;
  display: block;
  border-radius: 100px;
  position: relative;
}

.checkbox-wrapper-17 label:after {
  content: "";
  position: absolute;
  top: 6%;
  left: 2.5%;
  width: calc(50% - 5%);
  height: calc(100% - 11%);
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
}

.checkbox-wrapper-17 input:checked + label {
  background: #009b94;
}

.checkbox-wrapper-17 input:checked + label:after {
  left: calc(100% - 2.5%);
  transform: translateX(-100%);
}

.checkbox-wrapper-17 label:active:after {
  width: 55%;
}
.css-1ji6vxg.Mui-selected {
  color: #fff !important;
  background-color: #009b94 !important;
}
