.iconClass {
  color: var(--primary-color);
  border: 2px solid var(--primary-color);
  border-radius: 50%;
  padding: 0.1rem 0.2rem;
  margin-right: 10px;
}
.button {
  background-color: #fff;
  border: none;
  outline: none;
  padding: 0.5rem 1rem;
  border: 1px solid #fff;
  transition: all ease-out 0.2s;
  border-radius: 7px;
}
.button:hover {
  color: var(--primary-color);
}
.actionbuttons {
  display: flex;
  column-gap: 5px;
}
.saveButton {
  background: var(--primary-color);
  color: #fff;
}
.saveButton:hover {
  color: #fff;
  box-shadow: 0 0 5px 5px rgb(0 0 0 / 10%);
}
.cancelButton {
  color: #b00020;
}
.cancelButton:hover {
  color: #b00020;
  box-shadow: 0 0 5px 5px rgb(0 0 0 / 10%);
}
.specializationIcon {
  width: 50px;
  height: 50px;
}
.specializationIcon img {
  width: 100%;
  object-fit: scale-down;
  object-position: center;
}
.avatar {
  vertical-align: middle;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 30px;
}
.uploadImage {
  position: absolute;
  top: 101px;
  left: 266px;
}
