#snackbar {
  visibility: hidden;
  max-width: 350px;
  transform: translateX(-50%);
  background-color: #e0f3f2;
  color: #1e1e1e;
  text-align: center;
  border-radius: 8px;
  padding: 16px;
  position: fixed;
  z-index: 1111;
  left: 50%;
  top: 65px;
  font-size: 17px;
  box-shadow: 0 0 10px rgb(0 0 0 / 50%);
}

.show-danger {
  background-color: #d98792 !important;
  color: #ffffff !important;
  box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0,
    0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);

}

.show-info {
  background-color: #d9c287 !important;
  color: #ffffff !important;
  box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0,
    0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
}

.show-success {
  background-color: #e0f3f2 !important;
  color: #1e1e1e !important;
  box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0,
    0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
}

#snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}
.fa-check-circle {
  color: #009b94;
}

@-webkit-keyframes fadein {
  from {
    top: 0;
    opacity: 0;
  }
  to {
    top: 65px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    top: 0;
    opacity: 0;
  }
  to {
    top: 65px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    top: 65px;
    opacity: 1;
  }
  to {
    top: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    top: 65px;
    opacity: 1;
  }
  to {
    top: 0;
    opacity: 0;
  }
}
