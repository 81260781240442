/* Header main Title & publish Button */
.header {
  display: flex;
  justify-content: space-between;
  gap: 7px;
  margin: 32px 32px 16px 32px;
  padding: 1rem;
  background-color: rgb(255, 255, 255);
  border-radius: 0.5rem;
}
.header1 {
  display: flex;
  /* justify-content: space-between; */
  margin: 32px 32px 16px 32px;
  padding: 1rem;
  background-color: rgb(255, 255, 255);
  border-radius: 0.5rem;
}
.title{
  width: 60%;
}
.notification{
  width: 40%;
}
.options{
  width: 30%;
}
.options2{
  width: 25%;
}
.options1{
  width: 20%;
}
/*Right side of Page = Category */
.category {
  margin: 0px 32px 16px 0px;
  width: 25%;
  background-color: #fff;
  outline-style: none;
  border-radius: 0.5rem;
  padding: 20px 20px;
}
.Option {
  border-color: #cccccc;
  border-style: solid;
  width: 80%;
  height: 50px;
}
.Option:hover {
  border-color: #009b94;
}
.category select {
  margin-top: 0.5rem;
}
.category input:focus {
  border-color: #009b94;
}
.category input:hover {
  border-color: #009b94;
}
.reading {
  margin-top: 0.5rem;
}
.Authorimage {
  display: flex;
  gap: 10px;
}
.Authorimage input {
  border-color: #cccccc;
  border-style: solid;
  width: 124px;
  height: 50px;
  outline: none;
  padding: 5px;
  border-radius: 0.5rem;
  margin-top: 0.7rem;
}
.image_view {
  background-size: 80px 80px;
  width: 80px;
  height: 80px;
  /* margin: 0 20px; */
  border-radius: 5px;
}
/*left side of Page = Editor */
.main-editor {
  display: flex;
  justify-content: space-between;
}
.editor {
  margin: 0px 32px 16px 32px;
  width: 75%;
  height: 600px;
  padding-bottom: none;
  outline-style: none;
  border-radius: 0.5rem;
}
.ql-container.ql-snow {
  border: none !important;
  background-color: rgb(255, 255, 255);
  border-radius: 0.5rem;
}
.ql-toolbar.ql-snow {
  border: none !important;
  margin-bottom: 1rem;
  background-color: rgb(255, 255, 255);
  border-radius: 0.5rem;
  padding: 20px !important;
}
.ql-editor {
  font-size: 1rem !important;
  height: 580px !important;
  padding: 1rem 1.5rem !important;
}
.btnn{
  color: #009b94;
  background-color: #ffffff;
  border: 1px solid #009b94;
}
.btnn:hover{
  color: #ffffff;
  background-color: #009b94;
  border: 1px solid #009b94;
}
.btnn:focus{
  color: #009b94;
  background-color: #ffffff;
  border: 1px solid #009b94;
}
/* Responsiveness */

@media (max-width: 1025px) {
  .header {
    flex-wrap: wrap;
  }
  .header1 {
    flex-wrap: wrap;
  }
  .main-editor {
    display: flex;
    flex-wrap: wrap;
  }
  .main-editor .editor {
    width: 100%;
  }
  .main-editor .category {
    margin: 5rem 30px;
    width: 50%;
  }
}
@media (max-width: 768px) {
  .header {
    gap: 5px;
  }
  .header1 {
    gap: 5px;
  }
  .title{
    width: 60%;
  }
  .notification{
    width: 50%;
  }
  .options{
    width: 40%;
  }
  .options2{
    width: 25%;
  }
  .options1{
    width: 20%;
  }
  .main-editor .editor {
    width: 100%;
    margin-bottom: 10px;
  }
  .main-editor .category {
    width: 100%;
    margin: 110px 30px 0px 30px;
  }
}
@media (max-width: 600px) {
  .title{
    width: 100%;
  }
  .notification{
    width: 100%;
  }
  .options{
    width: 100%;
  }
  .options2{
    width: 100%;
  }
  .options1{
    width: 100%;
  }
}
