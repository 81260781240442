.address input{
    border: none;
    outline: none;
    border-bottom: 1px solid;
    width: 320px;
    border-color: #CCCCCC;
    padding-bottom: 6px;
}
.address label{
    color: #8e8e8e;
}
.servicetype{
    position: relative;
    margin-bottom: 20px;
    width: 100% !important;
}
.servicetype select{
    font-size: 16px;
    display: block;
    width: 320px;
    background-repeat: no-repeat;
    background-position-x: 38px;
    background-position-y: 21px;
    background-size: 551px 5px;
    cursor: pointer;
    position: absolute;
    top: 16px;
    left: 0px; 
    border-bottom: 1px solid #cccccc;
}
.servicelabel{
    color: #8e8e8e;
    font-size: 12px;
    font-weight: 400 !important;
    position: absolute;
    pointer-events: none;
    left: 1px;
    top: 0px;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
}
.span{
    font-size: 1rem;
    font-weight: 500;
    color: #1E1E1E;
}
.tab{
    gap: 42px;
}
.allwidth{
    width: 320px;
}
.newwidth{
    width: 320px;
    margin-right: 74px;
    margin-left: 1rem;
    padding-left: 0px;
}
.book{
    width: 136px;
    height: 48px;
    color: #FFFFFF;
    background: #009B94;
    border: none;
    padding: 12px 16px;
    border-radius: 4px;
}
button.book:disabled {
    background-color: #ccc;
    color: #666;
    cursor: not-allowed; 
  }
.discard{
    margin-left: 1rem;
    color: #B00020;
    border: none;
    background: none;
}
.card{
    width: 50%; 
    display: flex !important;
    flex-wrap: wrap !important;
}
.card_body{
    /* margin: 12px; */
    width: 100%;
    border: 1px solid #D7DDEA;
    box-shadow: 0px 2px 4px rgba(185, 185, 185, 0.04);
    border-radius: 6px;
    padding: 0.7rem;
}
.card_body button:focus{
    box-shadow: none;
}
.card_body p{
    margin: 0px;
    font-size: 14px;
}
.image img{
    border-radius: 50%;
    margin-right: 7px;
}
.M_Card{
    width: 50%;
    padding: 0.5rem 0 0 0.5rem;
}
.card_head span{
    border: 1px solid #D7DDEA;
    height: 25px;
    width: 80px;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    color: #1C6BA4;
}
.card_footer{
    margin-top: 7px;
}
.card_body_modal{
    margin: 12px;
}
.card_body_modal p{
    margin: 0px;
    font-size: 14px;
}
.Modal_button{
    margin-bottom: 36px;
    display: flex;
    gap: 10px;
}
.Modal_button button{
    height: 28px;
    width: 95px;
    font-size: 11px;
    border-radius: 24px;
    color: #009B94;
    background-color: #FFFFFF;
    border: 1px solid #009B94;
}
.Modal_button button:hover{
    background-color: #009B94;
    color: #FFFFFF;
}
.Modal_button button:focus{
    background-color: #009B94;
    color: #FFFFFF;
}
.f_button{
    margin-top: 56px;
}
.card_head_modal {
    display: grid;
    grid-template-columns: auto 1fr 95px;
    align-items: start;
}
.Modal_image {
    grid-column: 1;
    grid-row: 1 / span 3;
    height: 70px;
    width: 70px;
    margin-right: 10px;
}
.Modal_image img{ border-radius: 10px;}
.card_head_modal h6.card-title,span,p {
    grid-column: 2;
  }
.card_head_modal span.button {
    grid-column: 3;
    display: flex;
    justify-content: space-between;
    color: #1C6BA4;
    border: 1px solid #D7DDEA;
    height: 25px;
    width: 80px;
    border-radius: 4px;
}
.description {
    width: 250px;
    display: block;
    white-space: pre-wrap;
}
.modal_count_new.clicked {
    transform: scale(0.95);
  }
.modal_count_new{
    width: 90px;
    height: 25px;
    border-radius: 4px;
    font-size: 13px;
    color: #1C6BA4;
}
.appoint p{
    margin-top: 1rem;
    width: fit-content;
    border: 1px solid #D7DDEA;
    padding: 12px;
    border-radius: 12px;
}
@media only screen and (max-width: 1400px) and (min-width: 760px)   {
    .address input {
        width: 220px;
    }
    .servicetype select{
        width: 200px;
    }
    .M_Card{
        width: 100%;
    }
}