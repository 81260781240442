.corporatePageSection {
  padding: 3rem 3rem 3rem 0;
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  align-items: self-end;
}
.corporatePageSectionInner {
  padding: 1rem 3rem 3rem 0;
  display: flex;
  flex-direction: column;
  /* align-items: self-end; */
}
.addPartnerBtn {
  padding: 0.8rem 1rem !important;
  margin-right: 1rem !important;
}
.viewPartnerLink {
  color: blue;
}
.viewPartnerLink:hover {
  color: blue;
  text-decoration: underline !important;
}
.plansModalContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.actionBtnModal {
  display: flex;
  column-gap: 3rem;
}
.modalTextError {
  font-size: 22px;
  color: #af1300;
}
.modalText {
  text-align: center;
}
.actionBtn {
  padding: 0.8rem 1.5rem !important;
  display: flex;
  align-items: center;
}
.addPartnerModalContainer {
  display: grid;
  padding-bottom: 1rem;
}
.closeBtnPartnerModal {
  justify-self: end;
}
.addPartnerModal {
  display: grid;
  row-gap: 2rem;
  padding: 0 3rem 0rem 1rem;
}
.addPartnerModal div {
  width: 100%;
}
.error {
  border-color: #af1300 !important;
}
/* corporate Container */
.corporateOverViewContainer {
  padding: 2rem;
}
.corporateDataContainer {
  display: grid;
  grid-template-columns: 1fr 3fr;
  column-gap: 2rem;
}
.corporateInfo {
  background-color: #fff;
  border-radius: 8px;
  padding: 1rem;
}
.corporateInfo table {
  width: 100%;
}
.corporateInfo table tr {
  /* padding: 1rem; */
  border-bottom: 1px solid #ccc;
}
.corporateInfo table tr:last-child {
  /* padding: 1rem; */
  border-bottom: unset;
}
.corporateInfo table tr td {
  text-align: end;
  padding: 1rem 0;
}
.corporateInfo table tr th {
  padding: 1rem 0;
}
.corporateStats {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 1.5rem;
  row-gap: 1.5rem;
  width: 100%;
}
.statsCard {
  background-color: #fff;
  border-radius: 8px;
  padding: 1rem;
}
.settingsContainer {
  padding: 2rem;
}
.settingForm {
  width: 20%;
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  align-items: start;
}
.settingsField {
  width: 100%;
}
