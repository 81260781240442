
div.scrollmenu {
    border-radius: 8px;
    overflow: auto;
    white-space: nowrap;
}

div.scrollmenu a {
    display: inline-block;
    color: white;
    text-align: center;
    padding: 14px;
    text-decoration: none;
}

div.scrollmenu a:hover {
    background-color: #777;
}
.labelrdioclass{
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    border-radius: 8px;
    margin:4px;

}
.labelrdioclass:hover{
    color:#009B94;
    background: rgba(0, 155, 148, 0.08);
    border: 1px solid #009B94;
    border-radius: 8px;
}
.date_focused:focus{
    color:#009B94;
    background: rgba(0, 155, 148, 0.08);

}
.resetclass {
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    color: #5C5C5C;
}

.hover-Reschedule {
    background: #fff;
    border: 1px solid #009b94;
    color: #009b94;
    padding: 8px 15px;
    fontweight: 500;
}
.hover-Reschedule:hover {
    background: #009b94;
    border: 1px solid #009b94;
    color: #fff;
    padding: 8px 15px;
    fontweight: 500;
}
.fallowup_class:active {
    background: #009b94;
    color: #fff;
    border: 1px solid #009b94;
}
.fallowup_class:focus {
    background: #009b94;
    color: #fff;
    border: 1px solid #009b94;
}