.container{
    background-color: #fff;
}
.pic{
 border-radius: 50%;
 width: 45px;
 height: 45px;
}
.iconClass {
    color: var(--primary-color);
    border: 2px solid var(--primary-color);
    border-radius: 50%;
    padding: 0.1rem 0.2rem;
    margin-right: 10px;
}
.iconClass2 {
    color: var(--primary-color);
    padding: 0.1rem 0.2rem;
    margin-right: 0px;
}
.btn{
    box-shadow: none !important;
}
.specializationIcon {
    width: 50px;
    height: 50px;
}
.specializationIcon img {
    width: 100%;
    object-fit: scale-down;
    object-position: center;
}
.avatar {
    vertical-align: middle;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 30px;
}
.uploadImage {
    position: absolute;
    top: 135px;
    left: 75px;
}
.nbtn:hover{
    color: #009B94 !important;
    background-color: #fff !important;
}
.button {
    background-color: #fff;
    border: none;
    outline: none;
    padding: 0.5rem 1rem;
    border: 1px solid #fff;
    transition: all ease-out 0.2s;
    border-radius: 7px;
}
.button:hover {
    color: var(--primary-color);
}
.btn2{
    width: 80px;
    height: 40px;
    border: none;
    background-color: #009B94;
    border-radius: 5px;
    color: #fff;
    align-self: center;
    margin-top: 15px;
    padding: 5px;
}
.error-message {
    color: red;
}
.h_label{
    font-size: 1rem;
    font-weight: 400;
    color: #656363;
}
.h2_label{
    font-size: 1rem;
    font-weight: 400;
    color: #656363;
    margin-bottom: 0px;
}
.actionbuttons{
    display: flex;
    gap: 10px;
    margin: 15px;
}
.dropdown-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }