/* send notification page styling  */
.formFields {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  width: 70%;
}
.notificationTextArea {
  padding: 0.5rem;
  font-size: 0.8rem;
}
.sendNotificationBtn {
  padding: 0.8rem !important;
  /* text-align: start !important; */
}
/* .uploadingFile[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
} */
.notificationsImgContainer {
  position: relative;
}
.notificationImg {
  width: 300px;
}
.clearImgBtn {
  color: red !important;
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
}
