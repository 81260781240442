
.book{
    color: #FFFFFF;
    background: #009B94;
    border: none;
    padding: 12px 16px;
    border-radius: 4px;
}
.discard{
    margin-left: 1rem;
    color: #B00020;
    border: none;
    background: none;
}
.card_body{
    width: 100%;
    border: 1px solid #D7DDEA;
    box-shadow: 0px 2px 4px rgba(185, 185, 185, 0.04);
    border-radius: 6px;
    padding: 0.4rem;
}
.card_body button:focus{
    box-shadow: none;
}
.card_body p{
    margin: 0px;
    font-size: 14px;
}
.testList{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 1.5rem;
    column-gap: 1.2rem;
}
.testList li{
    list-style: none;
}
.cart{
    border: 1px solid #D7DDEA;
    box-shadow: 0px 2px 4px rgba(185, 185, 185, 0.04);
    border-radius: 6px;
    padding: 0.5rem;
}
.modal_count{
    width: 80px;
    height: 24px;
    border-radius: 4px;
    font-size: 11px;
    color: #009B94;
    /* text-align: end; */
}
.cartButton{
    background: none;
    border: none;
    font-size: 12px;
    text-align: center;
}
.card_footer{
    margin-top: 7px;
}
.card_body_modal{
    margin: 12px;
}
.card_body_modal p{
    margin: 0px;
    font-size: 14px;
}
.addTestButton{
    background: #009B94 !important;
    padding: 3px 12px !important;
    border: none !important;
    width: max-content;
}
@media only screen and (max-width: 1400px) and (min-width: 600px)   {
    .testList{
        grid-template-columns: 1fr 1fr;
    }
}
@media only screen and (max-width: 599px) {
    .testList{
        grid-template-columns: 1fr;
    }
}