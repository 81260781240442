

.group select {

  background-image: url("/src/resources/images/svgs/unSelectIcons/down_arrow_unselect.svg");

}

.group select:hover {
  background-image: url("/src/resources/images/svgs/selectIcon/down_arrow_select.svg");
}


     .aaddmethod {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  margin-left: 141px;
  letter-spacing: 0.5px;

  color: #5c5c5c;
}
.fa-lpus{
   color: #99d7d4 !important;
}
.fa-lpus:hover{
   color: #99d7d4 !important;
}
.btn-save {
  background-color: #009b94;
  padding: 8px 26px !important;
  letter-spacing: 1.25px;
  color: white;
}

.btn-save:hover {
  background-color: rgba(0, 155, 148, 0.84);
  color: rgba(255, 255, 255, 0.87);

}
.close{
  color: #009b94;
  border: none;
  background: #FFFFFF;
font-size: 30px;
}
